import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { YearObject } from "../../data/YearObject";

// Components
import ContentHeader from "../../components/ContentHeader";
import YearSelector from "../../components/YearSelector";
import BookletCard from "../../components/BookletCard";
import PhotosCard from "../../components/PhotosCard";
import Schedule from "../../components/Schedule";
import { ManagementCard } from "../../components/ManagementCard";
import SaveDate from "../../components/SaveDate";

// Current year query
// Get the year from the page context
export const query = graphql`
  query YearQuery($year: Int!) {
    years(year: { eq: $year }) {
      year
      color
      colored_banner
      theme
      video
      contentHtml
      save_date
      pdf {
        publicURL
      }
      banner {
        childImageSharp {
          gatsbyImageData(
            width: 2540
            height: 1200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER }
          )
        }
      }
      logo {
        childImageSharp {
          gatsbyImageData(
            width: 800
            height: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: CONTAIN }
            backgroundColor: "transparent"
          )
        }
      }
      people {
        name {
          full
        }
        picture {
          childImageSharp {
            gatsbyImageData(
              width: 80
              height: 80
              placeholder: BLURRED
              transformOptions: { fit: COVER }
            )
          }
        }
      }
      schedule {
        date
        times {
          begin
          end
        }
        title
        description
        link
        location {
          address
          name
          link
        }
        mootCourt {
          banner {
            childImageSharp {
              gatsbyImageData(
                width: 600
                height: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER }
              )
            }
          }
        }
        identifier
        seminar {
          banner {
            childImageSharp {
              gatsbyImageData(
                width: 600
                height: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER }
              )
            }
          }
        }
      }
      dates {
        begin
        end
      }
      people {
        name {
          full
        }
      }
      photos(limit: 24) {
        id
        childImageSharp {
          gatsbyImageData(
            width: 60
            height: 60
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER }
          )
        }
      }
    }
  }
`;

type YearPageProps = {
  data: {
    years: YearObject;
  };
};

export default class YearPage extends React.Component<YearPageProps> {
  wordBanner(): string[] | undefined {
    const { dates } = this.props.data.years;
    const now = new Date();
    const end = new Date(dates.end);
    if (end < now) {
      return [`Justitia ${this.props.data.years.year} is afgelopen`];
      // if the dates.begin is within the next 50 days, show the countdown
    } else if (
      new Date(dates.begin) < new Date(now.getTime() + 50 * 24 * 60 * 60 * 1000)
    ) {
      return [`VRIJDAG 12 MEI 2023: HET JUSTITIA CONGRES`];
    }
    return undefined;
  }

  bannerDate(date: Date): string {
    return date.toLocaleDateString("nl-NL", {
      day: "numeric",
      month: "long",
      weekday: "long",
    });
  }

  render() {
    const year = this.props.data.years;
    const banner = getImage(year.banner);
    const logo = getImage(year.logo);
    return (
      <div className="flex flex-col items-center">
        <ContentHeader
          topper={`${this.bannerDate(
            new Date(year.dates.begin)
          )} t/m ${this.bannerDate(new Date(year.dates.end))}`}
          title={`Justitia ${year.year.toString()}`}
          subtitle={logo ? undefined : year.theme}
          wordBanner={this.wordBanner()}
          background={logo ? undefined : banner}
          logo={logo}
          color={year.color}
          colored_banner={year.colored_banner ?? false}
          backLink="/"
          backLabel="Overzicht"
        />
        <div className="flex flex-col items-center w-full py-24 sm:gap-24 gap-8 px-6 max-w-screen-md">
          {year.video && <VideoCard year={year} />}
          {year.photos?.length > 0 && (
            <PhotosCard
              photos={year.photos}
              year={year.year}
              color={year.color}
            />
          )}
          {year.contentHtml && (
            <div className="flex flex-col gap-4">
              {year.contentHtml && (
                <div className="max-w-screen-md">
                  <div
                    className="year-content"
                    dangerouslySetInnerHTML={{ __html: year.contentHtml }}
                  />
                </div>
              )}
            </div>
          )}
          <Schedule
            schedule={year.schedule}
            color={year.color}
            hasBanners={true}
            year={year.year}
          />
          {year.people && year.people.length > 0 && (
            <ManagementCard
              people={year.people}
              year={year.year}
              color={year.color}
            />
          )}
          {year.pdf && (
            <div className="flex flex-col items-center gap-16 w-full max-w-screen-sm">
              <BookletCard
                url={year.pdf?.publicURL}
                text={`Bekijk het programmaboekje van Justitia ${year.year}`}
                color={year.color}
              />
            </div>
          )}
          <YearSelector year={year.year} color={year.color} />
        </div>
      </div>
    );
  }
}

function VideoCard(props: { year: YearObject }) {
  return (
    <iframe
      src={props.year.video}
      width="100%"
      frameBorder={0}
      allow="autoplay; fullscreen; picture-in-picture"
      className="w-full aspect-video rounded-xl overflow-hidden"
      allowFullScreen
      seamless
    />
  );
}

export function SignupCard() {
  return (
    <div className="bg-primary bg-opacity-10 p-8 rounded-xl shadow-sm">
      <h2 className="text-2xl font-bold text-primary">
        Inschrijvingen pleitwedstrijden
      </h2>
      <p className="py-6">
        Geïnteresseerden kunnen zich van donderdag 29 februari tot en met
        maandag 25 maart 2024 inschrijven via de knop hieronder.
      </p>
      <a href="https://forms.gle/9E6dNLh4JX8XNNe67">
        <div
          className="inline-flex flex-row items-center group gap-1 cursor-pointer hover:opacity-100 active:opacity-20 transition-all rounded-lg px-4 py-2 text-white"
          style={{
            background: "#1f0c35",
          }}
        >
          <p className="uppercase font-mediu group-hover:translate-x-1 transition-transform">
            Inschrijven
          </p>
          <span className="material-symbols-rounded text-2xl group-hover:translate-x-2 transition-transform">
            arrow_forward
          </span>
        </div>
      </a>
    </div>
  );
}

export function Head({ data }: { data: any }) {
  const year = data.years;
  return <title>Justitia {year.year}</title>;
}
